/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import "./src/scss/main.scss"
require("typeface-roboto")
require("typeface-open-sans")
require("typeface-stardos-stencil")

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(`This application has been updated. Reload to display the latest version ?`)

  if (answer === true) {
    window.location.reload()
  }
}
export const registerServiceWorker = () => true

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA}
                             scriptProps={{
                               async: true,
                               defer: true
                             }}>
      {element}
    </GoogleReCaptchaProvider>
  )
}
